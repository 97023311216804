<div class="container_wrap">
    <div class="" [ngClass]="{'container py-8': directMode}">
        <div class="flex flex-wrap items-center justify-between gap-2">
            <div class="flex items-center justify-between sm:justify-start w-full sm:w-auto gap-4">
                <h3 class="flex sm:hidden items-center text-2xl text-tit font-extrabold" *ngIf="directMode">
                    <a class="p-2" routerLink="/casino">
                        <svg class="w-4 h-4 fill-basic rotate-180"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    </a>{{ this.category[this.tag] }}
                </h3>
                <div class="hidden sm:inline-flex items-center gap-2 p-2 px-4 bg-backlight rounded" *ngIf="directMode">
                    <a href="javascript:;" routerLink="/casino" >카지노</a>
                    <svg class="w-3 h-3 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg>
                    <b class="font-bold text-tit">{{ this.category[this.tag] }}</b>
                </div>
            </div>
            <div class="flex md:flex-wrap items-center md:gap-x-6 md:gap-y-2">
                <div class="flex items-center gap-3">
                    <p class="hidden sm:flex">정렬 기준</p>
                    <div class="w-44">
                        <div class="custom_select flex-1">
                            <button class="btn h-10 flex px-4 items-center justify-between border-none bg-modaldark">
                                <span>좋아 할만한</span>
                                <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                            </button>
                            <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                                <li class="py-2" [ngClass]="{'on': !orderBy || orderBy == 'priority'}" (click)="onOrderBy('priority')">추천</li>
                                <li class="py-2" [ngClass]="{'on': orderBy == 'a-z'}" (click)="onOrderBy('a-z')">A-Z</li>
                                <li class="py-2" [ngClass]="{'on': orderBy == 'z-a'}" (click)="onOrderBy('z-a')">Z-A</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="flex items-center gap-3 ml-1">
                    <p  class="hidden sm:flex">제공사</p>
                    <div class="w-44">
                        <div class="custom_select flex-1">
                            <button class="btn h-10 flex px-4 items-center justify-between border-none bg-modaldark">
                                <div>{{ search_provider_text }}</div>
                                <i><svg class="w-3.5 h-3.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
                            </button>
                            <ul class="overflow-y-auto scrollbar p-2 shadow-basic rounded bg-modaldark text-sub">
                                <li class="flex items-center justify-between py-1 border-b border-solid border-slate-300 nohover">
                                    <button class="select_reset" (click)="onProviders('', $event)">모든 제공업체</button>
                                    <span class="text-primary">{{ items_total }}</span>
                                </li>
                                <ng-container *ngFor="let items of items_providers; let i = index;">
                                    <li class="py-2">
                                        <label class="flex items-center gap-2">
                                            <input type="checkbox" class="form-check-input grayborder" [checked]="items.checked" (change)="onProviders(items.provider_idx, $event)">
                                            <div class="flex items-center justify-between flex-1 w-3/4 pr-1" role="button">
                                                <span class="truncate">{{ items.name }}</span>
                                                <span class="text-primary">{{items.countBy}}</span>
                                            </div>
                                        </label>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="casino_list mt-4">
            <ng-template [ngIf]="isLoading">
                <app-loading />
            </ng-template>
            <li class="cursor-pointer bg-list rounded" *ngFor="let items of items_store; let i = index;">
                <a [routerLink]="'/casino/play/' + (items.game_idx || items.idx)">
                    <img [src]="items.image"  *ngIf="items.image" width="100%" height="100%" imgx />
                </a>
                <div class="flex items-center justify-between px-2 py-3">
                    <p class="font-medium truncate game_tit"><a href="javascript:;">{{ items.name }}</a></p>
                    <div class="casino_gameinfo" *ngIf="items['notice']">
                        <svg class="w-5 h-5 fill-basic cursor-pointer"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Inform"></use></svg>
                        <div class="hover_box p-2 bg-back2">
                            <div class="flex items-center justify-between bg-modaldark p-1">
                                <span>{{items['notice']}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- pager -->
        <div class="text-center mt-12">
            <div class="flex items-center justify-center gap-2 font-medium">
                <p>{{ items_store.length }} / {{ items_total }}</p>
                <div class="relative w-20 h-1.5 bg-list rounded overflow-hidden"><span class="absolute left-0 top-0 h-full bg-primary rounded" style="width:10%"></span></div>
                <p>{{ percentage }}%</p>
            </div>
            <button class="btn btn-normal h-11 w-36 mt-4 font-bold text-tit" (click)="GoPage(page + 1)">
                <span *ngIf="!isLoading">더보기</span>
                <div class="e9sva loading" *ngIf="isLoading">
                    <svg viewBox="0 0 84 24" fill="#fff">
                        <circle cx="18" cy="12" r="6"></circle>
                        <circle cx="18" cy="12" r="6"></circle>
                        <circle cx="42" cy="12" r="6"></circle>
                        <circle cx="66" cy="12" r="6"></circle>
                    </svg>
                </div>
            </button>
        </div>
    </div>
</div>